import React from 'react';

import { StaticQuery, graphql } from 'gatsby';

import PageHeader from '../../components/PageHeader';
import Post from '../../containers/Post';
import SiteTitle from '../../containers/SiteTitle';
import Layout from '../../layouts';

const PostsIndex = ({ location }) => (
  <Layout location={location}>
    <SiteTitle title="Notes" />
    <PageHeader header="Notes" />
    <StaticQuery
      query={graphql`
        query Notes {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
              fields: { slug: { glob: "/notes/**" } }
              frontmatter: { draft: { ne: true } }
            }
          ) {
            edges {
              node {
                ...PostFragment
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { edges: posts } }) => (
        <section>
          {posts.map(({ node }) => (
            <Post key={node.fields.slug} node={node} />
          ))}
        </section>
      )}
    />
  </Layout>
);

export default PostsIndex;
